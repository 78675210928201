import React, { memo } from "react";
import worldMap from './world-110m.json';

import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Graticule
} from "react-simple-maps";

const geoUrl = worldMap;

const rounded = num => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};

const MapChart = ({ setTooltipContent }) => {
  return (
    <>
      <ComposableMap data-tip="Pick country to learn more" projectionConfig={{ rotate: [-20, 0, 0], scale: 200 }}>
        <ZoomableGroup>
          <Graticule stroke="#E9EAEB" />
          <Geographies
            stroke="#FFFFFF"
            strokeWidth={0.5}
            geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { NAME, POP_EST, CONTINENT } = geo.properties;
                    setTooltipContent(`${NAME} — ${rounded(POP_EST)} - ${CONTINENT}`);
                  }}
                  onClick={() => {
                    const { NAME } = geo.properties;
                    fetch("https://discussplaces.com/api/search?term=" + NAME + "&in=titlesposts")
                      .then(response => response.json())
                      .then((jsonData) => {
                        // jsonData is parsed json object received from url
                        // console.log(jsonData.posts[0].category.name);
                        window.open("https://discussplaces.com/category/" + jsonData.posts[0].category.cid + "/" + jsonData.posts[0].category.name, '_parent');
                      })
                      .catch((error) => {
                        // handle your errors here
                        window.open("https://discussplaces.com/search?term=" + NAME + "&in=titlesposts", '_parent');
                        //console.error(error)
                      })
                    //
                  }}

                  style={{
                    default: {
                      fill: "#F299C0",
                      outline: "black"
                    },
                    hover: {
                      fill: "#6595DA",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E34025",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};



export default memo(MapChart);
